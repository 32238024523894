<template>
  <div class="event-info-sos">
    <div>
      <div class="time">
        {{ timeDuration }}
      </div>
    </div>
    <div class="info-items">
      <div class="group">
        <div class="group-title">{{ $t('device')/*設備*/ }}</div>
        <div class="items">
          <div class="item">
            <img src="@/assets/icons/Group.svg" alt="">
            <span class="group-name">{{ getGroupName(event.groupId) }}</span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('history_device')/*辨識設備*/" alwaysShow>
              <img src="@/assets/icons/Camera.svg" alt="">
            </TooltipElement>
            <span>{{ getDeviceTitleId(event.userAccount) }}</span>  
          </div>
          <div class="item">
            <TooltipElement :content="$t('history_fr_gps')/*GPS位置*/" alwaysShow>
              <img src="@/assets/icons/gps.svg" alt="">
            </TooltipElement>
            <span>{{ event.gps.latitude }}, {{ event.gps.longitude }}</span>
            <img class="map-icon" src="@/assets/icons/google-map-icon.png" @click="goGoogleMap" alt="">
          </div>
        </div>
      </div>
      <div class="group">
        <div class="group-title">{{ $t('link')/*勾稽*/ }}</div>
        <div class="items">
          <div class="item">
            <TooltipElement :content="$t('history_user_group')/*使用者群組*/" alwaysShow>
              <img src="@/assets/icons/Group.svg" alt="">
            </TooltipElement>
            <span class="group-name">{{ event.linkedGroupName }}</span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('history_link_user')/*勾稽使用者*/" alwaysShow>
              <img src="@/assets/icons/user.svg" alt="">
            </TooltipElement>
            <span v-if="event.linkedUserName">{{ `${ event.linkedUserName } (${ event.linkedUserAccount })` }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { apiEditBoviaLprEvent } from '@/api/index.js'
import { formatTime } from '@/utils/lib.js'

export default {
  name: 'EventInfoSos',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  props: {
    event: {
      type: Object
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('account', ['groupList']),
    ...mapGetters('account', ['getDeviceTitleId']),
    timeDuration() {
      return `${formatTime(this.event.startTime)} ~ ${formatTime(this.event.endTime)}`
    },
  },
  methods: {
    async setEventMisjudged(event) {
      let data = {
        event: [{id: event.id}],
        misjudged: event.misjudged === 1 ? 0 : 1
      }
      let res = await apiEditBoviaLprEvent(data)
      if (res.status === 204) {
        event.misjudged = event.misjudged === 1 ? 0 : 1
      }
    },
    goGoogleMap() {
      const url = `https://www.google.com.tw/maps/search/?api=1&query=${this.event.gps.latitude},${this.event.gps.longitude}`
      window.open(url, '_blank')
    },
    getGroupName(groupId) {
      const group = this.groupList.find((item) => item.id === groupId)
      return group ? group.name : ''
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.event-info-sos {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .time {
    display: inline-block;
    height: 34px;
    padding: 0 20px;
    border-radius: 17px;
    background: #4A5C78;
    font-size: 18px;
    font-weight: 700;
    line-height: 34px;
    color: #ffffff;
  }

  .info-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    font-size: 18px;
    line-height: 36px;
  }
  
  .group {
    display: flex;
    column-gap: 20px;
    padding: 8px 0;
    &:not(:last-child) {
      border-bottom: 1px solid #ffffff33;
    }
    .group-title {
      width: 80px;
      font-size: 18px;
      line-height: 24px;
      color: #FFE99F;
      font-weight: 700;
    }
    .items {
      flex: 1;
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      .item {
        display: flex;
        align-items: flex-start;
        column-gap: 12px;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        font-weight: 400;
        img {
          width: 24px;
          height: 24px;
          margin-top: 5px;
          &.map-icon {
            cursor: pointer;
          }
        }
        span {
          font-size: 18px;
          color: #ffffff;
          &.group-name {
            color: #FFD133;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>